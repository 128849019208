@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8);
}


.navbar {
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.445);
}


.navbar-brand,
.nav-link {
  font-family: 'Roboto', sans-serif;
}

.navbar-brand {
  font-size: 1.8rem;
  font-weight: bold;
}

.nav-link {
  font-size: 1.5rem;
  margin-right: 1rem;
}