.image-1 {
    height: 450px;
    width: 450px;
    max-width: 100%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .section-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section-1 h1 {
        margin: 0 40px;
    }

    .image-1 {
        width: 100%;
        height: auto;
        height: 250px;
        width: 250px;
        margin-top: 20px;
    }

}