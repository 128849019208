.footer {
  background-color: #343a4007;
  color: #ffffff;
  padding: 20px 0;
  text-align: center;
}

.footer .container {
  max-width: 900px;
  margin: 0 auto;
}

.footer p {
  margin: 0;
  padding: 5px 0;
}

.footer .social-icons {
  margin-top: 10px;
}

.footer .social-icons a {
  margin: 0 10px;
  text-decoration: none;
}

.footer .social-icons img {
  width: 32px;
  height: 32px;
  transition: transform 0.3s ease;
}

.footer .social-icons img:hover {
  transform: scale(1.2);
}