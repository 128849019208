body,
html {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

#init {
  width: 100vw;
  overflow-x: hidden;
}

.navbar {
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.445);
}

.bg-fixed {
  background-image: url('./assets/images/IA.jpg');
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

#section-1 {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.section-1 h1 {
  margin: 0;
  font-size: 2.5rem;
  color: white;
}

#section-1 canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  margin-top: 10vh;
}

.section-2 {
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
  height: auto;
}

.section-3 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
  height: auto;
  flex-direction: row;
}

.section-3 p {
  width: 450px;
  margin-right: 100px;
}

.content-overlay {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  text-align: center;
  color: white;
}

.section-plan {
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  margin: 0 auto;
  text-align: left;
}

.section-plan ol {
  margin-top: 50px;
  margin-bottom: 50px;
}

.section-plan ol {
  padding-left: 20px;
}

.section-plan li {
  margin-bottom: 10px;
}

#section-2>ol>li>strong>a {
  color: rgb(57, 57, 187);
}

@media (max-width: 768px) {
  .section-1 h1 {
    width: 300px;
    margin-top: 15px;
    font-size: 1.7rem;
  }

  .section-3 {
    flex-direction: column;
    text-align: center;
  }

  .section-3 p {
    margin-right: 0;
    margin-bottom: 20px;
    width: auto;
    padding: 0 20px;
  }

  .section-3 img {
    width: 250px;
    height: 250px;
  }
}