.section-mat {
    background-color: #1e1e1e;
    color: #f1f1f1;
    padding: 80px 20px 60px 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: calc(100vh - 60px);
    box-sizing: border-box;
    height: auto;
    width: auto;
}

.section-mat h2 {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 2rem;
    border-bottom: 2px solid #f1f1f1;
    padding-bottom: 10px;
    width: 100%;
    max-width: auto;
}

.topic {
    margin-bottom: 20px;
    width: 100%;
    max-width: 800px;
}

.topic h4 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #f9c74f;
}

.topic ul {
    list-style-type: disc;
    padding-left: 20px;
    line-height: 1.8;
}

.topic ul li {
    margin-bottom: 10px;
    font-size: 1.2rem;
    text-align: left;
}

.separator {
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 20px;
    margin-top: 20px;
    max-width: auto;
}

div>h3 {
    margin-top: 80px;
    margin-bottom: 20px;
}

#svd {
    padding-top: 80px;
    margin-top: -80px;
}

h5 {
    color: #f9c74f;
    display: inline-block;
    border-bottom: solid 1px #f9c74f;
    width: auto;
}


h3 {
    color: #f9c74f;
    border-bottom: 1px solid wheat;
    width: auto;
}



@media (max-width: 768px) {
    .section-mat {
        padding: 100px 20px 80px 20px;
        min-height: calc(100vh - 80px);
        width: 1750px;
    }

    .section-mat h2 {
        font-size: 1.8rem;
        margin-top: 10px;
    }

    .topic h4 {
        font-size: 1.3rem;
    }

    .topic ul li {
        font-size: 1.1rem;
    }

}

.img-container-1 {
    display: flex;
    justify-content: center;
}

.svd-img {
    background-image: url('../assets/images/svd_1.jpeg');
    width: 442px;
    height: 383px;
}


@media (max-width: 480px) {
    .section-mat {
        padding: 120px 10px 100px 10px;
        min-height: calc(100vh - 100px);
    }

    .section-mat h2 {
        font-size: 1.6rem;
        margin-top: 10px;
    }

    .topic h4 {
        font-size: 1.2rem;
    }

    .topic ul li {
        font-size: 1rem;
    }
}